var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-nice-card',{attrs:{"icon":"$clientsGroup","title":"clients","subtitle":"total clients:","subtitle_value":_vm.countAllClients,"to":"clients","color":"blue","subtitle_color":"light-blue","parameters_color":"cyan accent-4","loading":_vm.loadingClients,"parameters":!_vm.loadingClients ? [
          {
            key: 'clients',
            value: _vm.countBaseClients,
            icon: 'mdi-account-tie',
            size: 4,
          },
          {
            key: 'subclients',
            value: _vm.countSubClient,
            icon: 'mdi-account-tie-outline',
            size: 4,
          } ] : []}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-nice-card',{attrs:{"title":"users","icon":"mdi-account-group","subtitle":"total users:","subtitle_value":_vm.countUsers,"to":"users","color":"indigo","loading":_vm.loadingUsers,"subtitle_color":"indigo lighten-3","parameters_color":"indigo accent-3"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-nice-card',{attrs:{"title":"devices","icon":"$devices","subtitle":"total devices:","subtitle_value":_vm.countDevices,"to":"devices","color":"purple","subtitle_color":"purple lighten-3","parameters_color":"purple accent-3","loading":_vm.loadingDevices,"parameters":!_vm.loadingDevices ? [
          {
            key: 'online',
            value: _vm.countOnlineDevices,
            icon: 'mdi-router-wireless',
            size: 4,
          } ] : []}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }