<template>
  <v-container id="dashboard" fluid tag="section">
    <!-- Starter Stats -->
    <v-row>
      <!-- <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="info"
          icon="mdi-twitter"
          title="Followers"
          value="+245"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Website Visits"
          value="75.521"
          sub-icon="mdi-tag"
          sub-text="Tracked from Google Analytics"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          title="Revenue"
          value="$ 34,245"
          sub-icon="mdi-calendar"
          sub-text="Last 24 Hours"
        />
      </v-col> -->

      <v-col cols="12" sm="6" lg="3">
        <base-nice-card
          icon="$clientsGroup"
          title="clients"
          subtitle="total clients:"
          :subtitle_value="countAllClients"
          to="clients"
          color="blue"
          subtitle_color="light-blue"
          parameters_color="cyan accent-4"
          :loading="loadingClients"
          :parameters="!loadingClients ? [
            {
              key: 'clients',
              value: countBaseClients,
              icon: 'mdi-account-tie',
              size: 4,
            },
            {
              key: 'subclients',
              value: countSubClient,
              icon: 'mdi-account-tie-outline',
              size: 4,
            },
          ] : []"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-nice-card
          title="users"
          icon="mdi-account-group"
          subtitle="total users:"
          :subtitle_value="countUsers"
          to="users"
          color="indigo"
          :loading="loadingUsers"
          subtitle_color="indigo lighten-3"
          parameters_color="indigo accent-3"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-nice-card
          title="devices"
          icon="$devices"
          subtitle="total devices:"
          :subtitle_value="countDevices"
          to="devices"
          color="purple"
          subtitle_color="purple lighten-3"
          parameters_color="purple accent-3"
          :loading="loadingDevices"
          :parameters="!loadingDevices ? [
            {
              key: 'online',
              value: countOnlineDevices,
              icon: 'mdi-router-wireless',
              size: 4,
            },
          ] : []"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard',
  data: () => ({}),
  computed: {
    countBaseClients () {
      return this.$store.getters['clients/countBaseClients']
    },
    countSubClient () {
      return this.$store.getters['clients/countSubClients']
    },
    countAllClients () {
      return this.$store.getters['clients/countAllClients']
    },
    loadingClients () {
      return this.$store.getters['clients/loading']
    },
    countUsers () {
      return this.$store.getters['users/count']
    },
    loadingUsers () {
      return this.$store.getters['users/loading']
    },
    countDevices () {
      return this.$store.getters['devices/count']
    },
    countOnlineDevices () {
      return this.$store.getters['devices/countOnline']
    },
    loadingDevices () {
      return this.$store.getters['devices/loading']
    },
  },
  // mounted () {
  //   console.log('countAllClients', this.countAllClients)
  // },
  // methods: {
  //   //
  // },
}
</script>

<style lang="sass">
//
</style>
